import React from "react";
import { useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

export default function StickyFooter() {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography
          variant="h2"
          style={{
            fontWeight: "bold",
            fontSize: "150px",
            fontFamily: "initial",
          }}
          component="h1"
          gutterBottom
        >
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {"Oops.. The Page you are looking for is not available"}
          {"May be the Page is remove temporarily"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Want to try Login again!
        </Typography>
        <Button
          onClick={() => {
            history.push("/");
          }}
          variant="contained"
          color="primary"
        >
          Go To Login
        </Button>
      </Container>
    </div>
  );
}
