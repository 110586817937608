import React, { Component } from "react";
import { Formik, Form, FieldArray, validateYupSchema } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { TextField, Input } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as yup from "yup";
import data from "../Data/Data.json";

const validationSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      code: yup.object().typeError("Please select a code"),
      free: yup.string().required("Please select free option"),
      additionalPrice: yup.string().required("Please enter additional price"),
    })
  ),
});

class Permissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      UserFullData: [],
      ReportsData: [],
      RolesData: [],
      updatedValue: "",
      initialValues: {
        data: [],
      },
      selectedRoleID: 0,
      selectedReportID: 0,
    };
  }

  async getPermissions() {
    const DataUser = await axios.get("http://localhost:6060/permissions");
    const Userdata = DataUser.data;
    console.log(Userdata);
    this.setState({ initialValues: { data: Userdata } });
    console.log(this.state.initialValues);
  }
  async getReports() {
    const Data = await axios.get("http://localhost:6060/reports");
    const reportsdata = Data.data;
    console.log(reportsdata);
    this.setState({ ReportsData: reportsdata });
    console.log(this.state.ReportsData);
  }
  async getRoles() {
    const Data = await axios.get("http://localhost:6060/roles");
    const rolesdata = Data.data;
    console.log(rolesdata);
    this.setState({ RolesData: rolesdata });
    console.log(this.state.RolesData);
  }

  handleReportChange = (e) => {
    let value = e.target.value;
    this.setState({ selectedReportID: value });
    console.log(value);
  };

  handleRoleChange = (e) => {
    let value = e.target.value;
    this.setState({ selectedRoleID: value });
    console.log(value);
  };

  handlesub = (values) => {
    console.log(values.data);
  };

  async handleUpdate(row) {
    console.log(row);
    console.log(this.state.selectedReportID);
    console.log(this.state.selectedRoleID);
    if (row.permissionid === 0) {
      console.log("call insert api");
      fetch("http://localhost:6060/permissions", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          // row
          {
            permissionid: row.permissionid,
            reportid: this.state.selectedReportID,
            roleid: this.state.selectedRoleID,
            isactive: row.isactive,
            //loginid: row.loginid,

            //password: row.password,
            //userid: row.userid,
          }
        ),
      });
    } else {
      console.log("call update api");
      //await axios.get("http://localhost:6060/permissions");

      fetch("http://localhost:6060/permissions", {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          // row
          {
            permissionid: row.permissionid,
            reportid:
              this.state.selectedReportID === 0
                ? row.reportid
                : this.state.selectedReportID,
            roleid:
              this.state.selectedRoleID === 0
                ? row.roleid
                : this.state.selectedRoleID,
            isactive: row.isactive,
          }
        ),
      });
    }
  }

  handleRemove = (idx) => {
    console.log(idx.permissionid);
    fetch(`http://localhost:6060/permissions/${idx.permissionid}`, {
      method: "DELETE",
    });
  };

  componentDidMount() {
    this.getPermissions();
    this.getReports();
    this.getRoles();
  }

  UserOnChange(e) {
    this.setState({ SelectedDepartment: { id: e.value, name: e.label } });

    const Docoptions = this.state.ServiceprovidersFulldata.filter(
      (doc) => e.value == doc.deptid
    );
    this.setState({ Serviceproviders: Docoptions });
  }

  render() {
    if (!this.state.initialValues.data.length) return null;
    return (
      <div>
        <Grid container direction="row">
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardHeader
                style={{
                  backgroundColor: "white",
                  color: "#546e7a",
                  justifyContent: "left",
                  padding: "10px 5px",
                  fontWeight: "bold",
                }}
                title={"Manage Permissions"}
              />
              <Divider />
              <CardContent>
                <Formik
                  initialValues={this.state.initialValues}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values.data, null, 2));
                      actions.setSubmitting(false);
                    }, 1000);
                  }}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    handleBlur,
                    touched,
                    handleSubmit,
                    // handleReportChange,
                    // handleRoleChange,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <FieldArray name="data">
                          {({ insert, remove, push }) => (
                            <Paper>
                              <TableContainer>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Report</TableCell>
                                      <TableCell>Role</TableCell>
                                      <TableCell>isActive</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values.data.map((record, idx) => {
                                      return (
                                        <TableRow key={idx} hover>
                                          <TableCell>
                                            <FormControl
                                              size="small"
                                              variant="outlined"
                                            >
                                              <Select
                                                onChange={
                                                  this.handleReportChange
                                                }
                                                defaultValue={record.reportid}
                                              >
                                                {this.state.ReportsData.map(
                                                  (record, idx) => {
                                                    return (
                                                      <MenuItem
                                                        value={record.reportid}
                                                      >
                                                        {record.name}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell>
                                            <FormControl
                                              size="small"
                                              variant="outlined"
                                            >
                                              <Select
                                                onChange={this.handleRoleChange}
                                                defaultValue={record.roleid}
                                              >
                                                {this.state.RolesData.map(
                                                  (record, idx) => {
                                                    return (
                                                      <MenuItem
                                                        key={idx}
                                                        value={record.roleid}
                                                      >
                                                        {record.name}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </FormControl>
                                          </TableCell>

                                          <TableCell>
                                            <Checkbox
                                              name="checkbox"
                                              color="primary"
                                              inputProps={{
                                                "aria-label":
                                                  "secondary checkbox",
                                              }}
                                              // checked={
                                              //   values.data[idx].isactive
                                              // }

                                              name={`data.${idx}.isactive`}
                                              checked={
                                                values.data[idx] &&
                                                values.data[idx].isactive
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              error={Boolean(
                                                touched.data &&
                                                  touched.data[idx] &&
                                                  touched.data[idx]
                                                    .additionalPrice &&
                                                  errors.data &&
                                                  errors.data[idx] &&
                                                  errors.data[idx]
                                                    .additionalPrice
                                              )}
                                              helperText={
                                                touched.data &&
                                                touched.data[idx] &&
                                                touched.data[idx]
                                                  .additionalPrice &&
                                                errors.data &&
                                                errors.data[idx] &&
                                                errors.data[idx].additionalPrice
                                                  ? errors.data[idx]
                                                      .additionalPrice
                                                  : ""
                                              }
                                              onChange={handleChange}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              variant="contained"
                                              style={{
                                                backgroundColor: "#ffd300",
                                              }}
                                              onClick={() =>
                                                this.handleUpdate(
                                                  values.data[idx]
                                                )
                                              }
                                            >
                                              Update
                                            </Button>{" "}
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                              onClick={() => {
                                                this.handleRemove(
                                                  values.data[idx]
                                                );
                                                remove(idx);
                                              }}
                                            >
                                              Remove
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <div style={{ width: "100%" }}>
                                <Button
                                  style={{ marginLeft: "10px" }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    push({
                                      permissionid: 0,
                                      reportid: 0,
                                      roleid: 0,
                                      isactive: true,
                                    })
                                  }
                                >
                                  Add Row
                                </Button>{" "}
                                <Button
                                  style={{
                                    marginRight: "20px",
                                    marginBottom: "20px",
                                    backgroundColor: "",
                                    marginTop: "20px",
                                  }}
                                  variant="contained"
                                  color="default"
                                  type="submit"
                                  onClick={() => this.handlesub(values)}
                                >
                                  Save
                                </Button>
                                {/* <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                >
                                  Save
                                </Button> */}
                              </div>
                            </Paper>
                          )}
                        </FieldArray>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Permissions;
