import React, { Component } from "react";
import { Formik, Form, FieldArray, validateYupSchema } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as yup from "yup";
import data from "../Data/Data.json";

const validationSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      code: yup.object().typeError("Please select a code"),
      free: yup.string().required("Please select free option"),
      additionalPrice: yup.string().required("Please enter additional price"),
    })
  ),
});

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      UserFullData: [],
      updatedValue: "",
      initialValues: {
        data: [],
      },
    };
  }

  async getSettings() {
    const DataUser = await axios.get("http://localhost:6060/config");
    const Userdata = DataUser.data;
    console.log(Userdata);
    this.setState({ initialValues: { data: Userdata } });
    console.log(this.state.initialValues);
  }

  handleChange = (e) => {
    let value = e.target.value;
    this.setState({ updatedValue: value });
  };

  handlesub = (values) => {
    console.log(values.data);
  };

  async handleUpdate(row) {
    console.log(row);
    if (row.roleid === 0) {
      console.log("call insert api");
      fetch("http://localhost:6060/config", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          // row
          {
            tconfigid: row.tconfigid,
            key: row.key,
            value: row.value,
            isactive: "1",
          }
        ),
      });
    } else {
      console.log("call update api");
      //await axios.get("http://localhost:6060/config");

      fetch("http://localhost:6060/config", {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          // row
          {
            value: row.value,
            isactive: "1",
            key: row.key,
            tconfigid: row.tconfigid,
          }
        ),
      });
    }
  }

  handleRemove = (idx) => {
    console.log(idx.tconfigid);
    fetch(`http://localhost:6060/config/${idx.tconfigid}`, {
      method: "DELETE",
    });
  };

  componentDidMount() {
    this.getSettings();
  }

  UserOnChange(e) {
    this.setState({ SelectedDepartment: { id: e.value, name: e.label } });

    const Docoptions = this.state.ServiceprovidersFulldata.filter(
      (doc) => e.value == doc.deptid
    );

    this.setState({ Serviceproviders: Docoptions });
  }

  render() {
    if (!this.state.initialValues.data.length) return null;
    return (
      <div>
        <Grid container direction="row">
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardHeader
                style={{
                  backgroundColor: "white",
                  color: "#546e7a",
                  justifyContent: "left",
                  padding: "10px 5px",
                  fontWeight: "bold",
                }}
                title={"Manage Settings"}
              />
              <Divider />
              <CardContent>
                <Formik
                  initialValues={this.state.initialValues}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values.data, null, 2));
                      actions.setSubmitting(false);
                    }, 1000);
                  }}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    handleBlur,
                    touched,
                    handleSubmit,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <FieldArray name="data">
                          {({ insert, remove, push }) => (
                            <Paper>
                              <TableContainer>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Value</TableCell>
                                      <TableCell>isActive</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values.data.map((record, idx) => {
                                      return (
                                        <TableRow key={idx} hover>
                                          <TableCell>
                                            <TextField
                                              style={{ width: "250px" }}
                                              size="small"
                                              variant="outlined"
                                              name={`data.${idx}.key`}
                                              value={
                                                values.data[idx] &&
                                                values.data[idx].key
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              error={Boolean(
                                                touched.data &&
                                                  touched.data[idx] &&
                                                  touched.data[idx]
                                                    .additionalPrice &&
                                                  errors.data &&
                                                  errors.data[idx] &&
                                                  errors.data[idx]
                                                    .additionalPrice
                                              )}
                                              helperText={
                                                touched.data &&
                                                touched.data[idx] &&
                                                touched.data[idx]
                                                  .additionalPrice &&
                                                errors.data &&
                                                errors.data[idx] &&
                                                errors.data[idx].additionalPrice
                                                  ? errors.data[idx]
                                                      .additionalPrice
                                                  : ""
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              style={{ width: "200px" }}
                                              size="small"
                                              variant="outlined"
                                              name={`data.${idx}.value`}
                                              value={
                                                values.data[idx] &&
                                                values.data[idx].value
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              error={Boolean(
                                                touched.data &&
                                                  touched.data[idx] &&
                                                  touched.data[idx]
                                                    .additionalPrice &&
                                                  errors.data &&
                                                  errors.data[idx] &&
                                                  errors.data[idx]
                                                    .additionalPrice
                                              )}
                                              helperText={
                                                touched.data &&
                                                touched.data[idx] &&
                                                touched.data[idx]
                                                  .additionalPrice &&
                                                errors.data &&
                                                errors.data[idx] &&
                                                errors.data[idx].additionalPrice
                                                  ? errors.data[idx]
                                                      .additionalPrice
                                                  : ""
                                              }
                                            />
                                          </TableCell>

                                          <TableCell>
                                            <Checkbox
                                              name="checkbox"
                                              color="primary"
                                              inputProps={{
                                                "aria-label":
                                                  "secondary checkbox",
                                              }}
                                              // checked={
                                              //   values.data[idx].isactive
                                              // }

                                              name={`data.${idx}.isactive`}
                                              checked={
                                                values.data[idx] &&
                                                values.data[idx].isactive
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              error={Boolean(
                                                touched.data &&
                                                  touched.data[idx] &&
                                                  touched.data[idx]
                                                    .additionalPrice &&
                                                  errors.data &&
                                                  errors.data[idx] &&
                                                  errors.data[idx]
                                                    .additionalPrice
                                              )}
                                              helperText={
                                                touched.data &&
                                                touched.data[idx] &&
                                                touched.data[idx]
                                                  .additionalPrice &&
                                                errors.data &&
                                                errors.data[idx] &&
                                                errors.data[idx].additionalPrice
                                                  ? errors.data[idx]
                                                      .additionalPrice
                                                  : ""
                                              }
                                              onChange={handleChange}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              variant="contained"
                                              style={{
                                                backgroundColor: "#ffd300",
                                              }}
                                              onClick={() =>
                                                this.handleUpdate(
                                                  values.data[idx]
                                                )
                                              }
                                            >
                                              Update
                                            </Button>{" "}
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                              onClick={() => {
                                                this.handleRemove(
                                                  values.data[idx]
                                                );
                                                remove(idx);
                                              }}
                                            >
                                              Remove
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <div style={{ width: "100%" }}>
                                <Button
                                  style={{ marginLeft: "10px" }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    push({
                                      roleid: 0,
                                      name: "",
                                      arname: "",

                                      isactive: true,
                                    })
                                  }
                                >
                                  Add Row
                                </Button>{" "}
                                <Button
                                  style={{
                                    marginRight: "20px",
                                    marginBottom: "20px",
                                    backgroundColor: "",
                                    marginTop: "20px",
                                  }}
                                  variant="contained"
                                  color="default"
                                  type="submit"
                                  onClick={() => this.handlesub(values)}
                                >
                                  Save
                                </Button>
                              </div>
                            </Paper>
                          )}
                        </FieldArray>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Settings;
