import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import TableChartIcon from "@material-ui/icons/TableChart";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import ListItem from "@material-ui/core/ListItem";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useHistory } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PublicIcon from "@material-ui/icons/Public";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import TimerIcon from "@material-ui/icons/Timer";
import SettingsIcon from "@material-ui/icons/Settings";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";

import { Button, Link } from "@material-ui/core";

// const categories = [
//   {
//     id: "Sales ",
//     children: [
//       { id: "Forecast", icon: <PeopleIcon />, active: true },
//       { id: "Actual Sales", icon: <DnsRoundedIcon /> },
//       { id: "Customer wise", icon: <PermMediaOutlinedIcon /> },
//       { id: "Material Wise", icon: <PublicIcon /> },
//       { id: "Missed Sales", icon: <SettingsEthernetIcon /> },
//       { id: "Sales Values", icon: <SettingsInputComponentIcon /> },
//     ],
//   },
//   {
//     id: "Purchase",
//     children: [
//       { id: "Forecast", icon: <SettingsIcon /> },
//       { id: "Actual Purchase", icon: <TimerIcon /> },
//       { id: "Supplier Wise", icon: <PhonelinkSetupIcon /> },
//       { id: "Material Wise", icon: <PhonelinkSetupIcon /> },
//     ],
//   },
//   {
//     id: "Admin",
//     children: [
//       { id: "Users", path: "/Users", icon: <PeopleIcon /> },
//       { id: "Roles", path: "/Roles", icon: <TimerIcon /> },
//       { id: "Reports", path: "/Reports", icon: <DashboardIcon /> },
//       { id: "Permissions", path: "/Permissions", icon: <FingerprintIcon /> },
//     ],
//   },
//   {
//     id: "Users",
//     children: [
//       { id: "Users", path: "/Users" },
//       { id: "Roles", path: "/Roles" },
//       { id: "Reports", path: "/Reports" },
//       { id: "Permissions", path: "/Permissions" },
//     ],
//   },
// ];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 20,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, ...other } = props;
  const userapidata = props.userapidata;
  const [categories, setcategories] = useState([]);
  const userapidata1 = [["sdf"], ["werwer"]];
  useEffect(() => {
    console.log("Navigator", userapidata);

    setcategories([
      {
        companyname: "sdf", //userapidata[0][0],
        title: userapidata[0][1],
        children: userapidata,
      },
    ]);
  }, []);

  const history = useHistory();

  return (
    <Drawer style={{ scrollbarColor: "red" }} variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          {userapidata[0][0]}
        </ListItem>

        {categories.map(({ id, title, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {title}
              </ListItemText>
            </ListItem>

            {children.map((child) => (
              <ListItem
                onClick={() =>
                  history.replace("/Home", {
                    data: userapidata,
                    iframelink: child[3],
                  })
                }
                key={id}
                button
                className={clsx(classes.item, classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  <Link style={{ color: "white", textDecoration: "none" }}>
                    {" "}
                    {child[2]}
                  </Link>
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />

            <ListItem
              onClick={() =>
                history.replace("/", {
                  data: userapidata,
                })
              }
              key={id}
              button
              className={clsx(classes.item, classes.itemActiveItem)}
            >
              <ListItemIcon className={classes.itemIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                <Link style={{ color: "white", textDecoration: "none" }}>
                  {"Log Out"}
                </Link>
              </ListItemText>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
