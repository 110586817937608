import React, { createContext, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import axios from "axios";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Link from "@material-ui/core/Link";
import { Link, Redirect, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Home from "../PaperBase/Home";
const fs = require("fs");

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Mezix.com
      </Link>
      {"  "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#3f9ce5",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validate = {
  userName: "Admin",
  Password: "1234",
};

export default function SignIn() {
  const history = useHistory();
  const classes = useStyles();
  const [userName, setUserName] = useState("dummy");
  const [Password, setPassword] = useState("dummy");
  const [wrongCred, setwrongCred] = useState(".");

  const [apidata, setapidata] = useState("");

  const OnSubmit = () => {
    console.log("submit");
    setwrongCred("");
    const id = 20;

    const fetchData = async (userName, Password) => {
      try {
        console.log(
          "process.env.REACT_APP_API_URL = ",
          `${process.env.REACT_APP_API_URL}`
        );
        const apiUrl = `https://biapi.mezix.com/api/${userName}`;
        //const apiUrl = `http://localhost:6060/api/${userName}`;
        console.log(apiUrl);
        //const apiUrl = `${process.env.REACT_APP_API_URL}/${userName}`;
        const response = await axios
          .get(apiUrl, {
            params: {
              id: userName,
              pwd: Password,
            },
          })
          .then((response) => {
            const jsonData = response.data;
            console.log("Fetched data:", jsonData);
            if (!jsonData[0]) {
              history.replace("/Home", {
                data: jsonData.data,
                iframelink: jsonData.data[0][2],
              });
              console.log("Login Success");
              setapidata(jsonData);
            } else {
              setwrongCred("Wrong User Name or Password !");
              history.replace("/404");
              console.log("Login Failed");
            }
          })
          .catch((error) => {
            // Handle errors, such as network issues or server errors
            setwrongCred("Wrong User Name or Password !");
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    console.log("before api call", userName, Password);
    fetchData(userName, Password);

    //   const apiUrl = `http://localhost:6060/api/${userName}`;
    //   fetchData;
    //   fetch(apiUrl)
    //     .then((response) => response.json())
    //     .then((jsonData) => {
    //       if (!jsonData[0]) {
    //         history.replace("/Home", {
    //           data: jsonData.data,
    //           iframelink: jsonData.data[0][2],
    //         });
    //         console.log("Login Success");
    //         setapidata(jsonData);
    //       } else {
    //         history.replace("/404");
    //         console.log("Login Failed");
    //       }
    //     });
    //   //  .then((data) => console.log("This is your data", data[0].password));
  };

  useEffect(() => {}, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Container
        style={{
          border: "1px solid black",
          marginLeft: "350px",
          width: "500px",
          height: "600px",
          textAlign: "center",
          marginTop: "100px",
          borderRadius: "10px",
        }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="UserName"
              label="User Name"
              name="UserName"
              autoComplete="UserName"
              autoFocus
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              //type="submit"
              onClick={OnSubmit}
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#3f9ce5", color: "white" }}
              className={classes.submit}
            >
              {/* <Link
                style={{ textDecoration: "none", color: "white" }}
                //to="/Home"
              ></Link> */}
              Sign In
            </Button>
            <InputLabel variant="filled" style={{ color: "red" }}>
              {wrongCred}
            </InputLabel>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
