import React, { Component } from "react";
import PropTypes from "prop-types";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
// import Paper from "@material-ui/core/Paper";
// import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Tabbing from "../Components/Tabs";
import axios from "axios";

import "chartjs-plugin-datalabels";
import {
  Line,
  Bar,
  Pie,
  Polar,
  Radar,
  Doughnut,
  HorizontalBar,
  ChartData,
} from "react-chartjs-2";

// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

// function Content(props) {
//   const { classes } = props;

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // PieChartData: {
      //   labels: [],
      //   datasets: [
      //     {
      //       label: "# of Sales",
      //       data: [0, 1, 4, 3, 5, 6],
      //       backgroundColor: [
      //         "rgba(255, 99, 132, 1)",
      //         "rgba(54, 162, 235, 1)",
      //         "rgba(255, 206, 86, 1)",
      //         "rgba(75, 192, 192, 1)",
      //         "rgba(153, 102, 255, 1)",
      //         "rgba(255, 159, 64, 1)",
      //       ],
      //       borderColor: [
      //         "rgba(255, 99, 132, 1)",
      //         "rgba(54, 162, 235, 1)",
      //         "rgba(255, 206, 86, 1)",
      //         "rgba(75, 192, 192, 1)",
      //         "rgba(153, 102, 255, 1)",
      //         "rgba(255, 159, 64, 1)",
      //       ],
      //       borderWidth: 3,
      //     },
      //   ],
      // },
    };
  }

  async getData() {
    const DataUser = await axios.get("http://localhost:6060/data");
    const Userdata = DataUser.data[0];
    console.log(DataUser.data[0].amount);

    //this.setState({ PieChartData: { labels: DataUser.data[0].month } });
    // this.setState({
    //   PieChartData: { datasets: [{ data: DataUser.data[0].amount }] },
    // });

    const jsonresult = await axios.get("http://localhost:6060/reportdata");
    console.log(jsonresult.data[0].get_reportdata);

    this.setState({
      PieChartData: jsonresult.data[0].get_reportdata,
      // {
      //   labels: DataUser.data[0].month,
      //   datasets: [
      //     {
      //       label: "# of Sales",
      //       data: DataUser.data[0].amount,
      //       backgroundColor: [
      //         "rgba(255, 99, 132, 1)",
      //         "rgba(54, 162, 235, 1)",
      //         "rgba(255, 206, 86, 1)",
      //         "rgba(75, 192, 192, 1)",
      //         "rgba(153, 102, 255, 1)",
      //         "rgba(255, 159, 64, 1)",
      //       ],
      //       borderColor: [
      //         "rgba(255, 99, 132, 1)",
      //         "rgba(54, 162, 235, 1)",
      //         "rgba(255, 206, 86, 1)",
      //         "rgba(75, 192, 192, 1)",
      //         "rgba(153, 102, 255, 1)",
      //         "rgba(255, 159, 64, 1)",
      //       ],
      //       borderWidth: 3,
      //     },
      //   ],
      // },
    });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <Tabbing>
        <div>
          <div style={{ width: "100% ", height: "50% ", display: "flex" }}>
            <Card
              style={{
                width: "100% auto",
                height: "100% auto",
                padding: "20px",
                margin: "20px",
                width: "50%",
              }}
            >
              <Line data={this.state.PieChartData} type="bar" />
            </Card>
            <Card
              style={{
                width: "100%",
                height: "100%",
                padding: "20px",
                margin: "20px",
                width: "50%",
              }}
            >
              <Bar data={this.state.PieChartData} type="bar" />
            </Card>
            <Card
              style={{
                width: "100%",
                height: "100%",
                padding: "20px",
                margin: "20px",
                width: "50%",
              }}
            >
              <Pie data={this.state.PieChartData} type="bar" />
            </Card>
          </div>
          <div style={{ width: "100%", height: "50%", display: "flex" }}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                padding: "20px",
                margin: "20px",
                width: "50%",
              }}
            >
              <HorizontalBar data={this.state.PieChartData} type="bar" />
            </Card>
            <Card
              style={{
                width: "100%",
                height: "100%",
                padding: "20px",
                margin: "20px",
                width: "50%",
              }}
            >
              <Doughnut data={this.state.PieChartData} type="bar" />
            </Card>
            <Card
              style={{
                width: "100%",
                height: "100%",
                padding: "20px",
                margin: "20px",
                width: "50%",
              }}
            >
              <Radar data={this.state.PieChartData} type="bar" />
            </Card>
          </div>
          <div style={{ width: "%", height: "50%", display: "flex" }}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                padding: "20px",
                margin: "20px",
              }}
            >
              <iframe
                width="100%"
                height="400"
                frameborder="0"
                scrolling="no"
                src="https://onedrive.live.com/embed?resid=AE6E518114B5A983%213480&authkey=%21AOHJJrZO9AG-kFo&em=2&wdAllowInteractivity=False&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True"
              ></iframe>
            </Card>
          </div>
        </div>
      </Tabbing>
    );
  }
}

// Content.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(Content);
export default Content;
