import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import axios from "axios";
import CardContent from "@material-ui/core/CardContent";

import TableComp from "../Theme/TableComp";

import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      code: yup.object().typeError("Please select a code"),
      free: yup.string().required("Please select free option"),
      additionalPrice: yup.string().required("Please enter additional price"),
    })
  ),
});

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      UserFullData: [],
      RoleData: [],
      SelectedRoleID: 0,
      updatedValue: "",
      initialValues: {
        data: [],
      },
    };
  }

  async getUsers() {
    const DataUser = await axios.get("http://localhost:6060/users");
    const Userdata = DataUser.data;
    console.log(Userdata);
    this.setState({ initialValues: { data: Userdata } });
    console.log(this.state.initialValues);
  }
  async getRoles() {
    const result = await axios.get("http://localhost:6060/roles");
    console.log(result.data);
    this.setState({ RoleData: result.data });
    console.log(this.state.RoleData);
  }

  handlerChange = (e) => {
    console.log("1243");
    let value = e.target.name == "roleid" ? e.target.roleid : e.target.roleid;
    this.setState({ updatedValue: value });
  };

  handleRoleChange = (e) => {
    let value = e.target.value;
    this.setState({ SelectedRoleID: value });
    console.log(`this is the value : ${value}`);
    console.log(`and this is the saved data : ${this.state.SelectedRoleID}`);
  };

  handlesub = (values) => {
    console.log(values.data);
  };

  async handleUpdate(row) {
    console.log(row);
    console.log(this.state.SelectedRoleID);
    if (row.userid === 2233) {
      console.log("call insert api");
      fetch("http://localhost:6060/users", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          // row
          {
            arname: row.arname,
            isactive: row.isactive,
            loginid: row.loginid,
            name: row.name,
            roleid: this.state.SelectedRoleID,
            password: row.password,
            userid: row.userid,
          }
        ),
      });
    } else {
      console.log("call update api");
      //await axios.get("http://localhost:6060/users");
      console.log(row.isactive);
      fetch("http://localhost:6060/users", {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          // row
          {
            arname:
              this.state.SelectedRoleID === 0
                ? row.arname
                : this.state.SelectedRoleID,
            isactive:
              this.state.SelectedRoleID === 0
                ? row.isactive
                : this.state.SelectedRoleID,
            loginid:
              this.state.SelectedRoleID === 0
                ? row.loginid
                : this.state.SelectedRoleID,
            name:
              this.state.SelectedRoleID === 0
                ? row.name
                : this.state.SelectedRoleID,
            roleid:
              this.state.SelectedRoleID === 0
                ? row.roleid
                : this.state.SelectedRoleID,
            password:
              this.state.SelectedRoleID === 0
                ? row.password
                : this.state.SelectedRoleID,
            userid:
              this.state.SelectedRoleID === 0
                ? row.userid
                : this.state.SelectedRoleID,
          }
        ),
      });
    }
  }

  handleRemove = (idx) => {
    console.log(idx.userid);
    fetch(`http://localhost:6060/users/${idx.userid}`, {
      method: "DELETE",
    });
  };

  componentDidMount() {
    this.getUsers();
    this.getRoles();
  }

  UserOnChange(e) {
    this.setState({ SelectedDepartment: { id: e.value, name: e.label } });

    const Docoptions = this.state.ServiceprovidersFulldata.filter(
      (doc) => e.value == doc.deptid
    );
    this.setState({ Serviceproviders: Docoptions });
  }

  render() {
    if (!this.state.initialValues.data.length) return null;
    return (
      <div>
        <Grid container direction="row">
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardHeader
                style={{
                  backgroundColor: "white",
                  color: "#546e7a",
                  justifyContent: "left",
                  padding: "10px 5px",
                  fontWeight: "bold",
                }}
                title={"Tabular View"}
              />
              <Divider />
              <CardContent>
                <TableComp />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Users;
