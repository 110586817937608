//import logo from "./logo.svg";
import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import Login from "./Containers/Login";
import fourzerofour from "./Containers/404";
import { Hidden } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Home from "./PaperBase/Home";
import UserPage from "./Containers/UserPage";
import TableView from "./Containers/TableView";
import SettingsPage from "./Containers/SettingsPage";
import RolePage from "./Containers/RolePage";
import ReportsPage from "./Containers/ReportsPage";
import PermissionsPage from "./Containers/PermissionsPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Navigator from "./PaperBase/Navigator";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = "16%";

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
};

// Create a new context
const UserContext = createContext();

// Create a provider component
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Function to update the user
  const updateUser = (userData) => {
    setUser(userData);
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Create a consumer hook
const useUser = () => {
  const context = React.useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

// Usage in components
const UserProfile = () => {
  const { user } = useUser();

  return (
    <div>
      <h2>User Profile</h2>
      {user ? <p>Welcome, {user.username}!</p> : <p>Please login.</p>}
    </div>
  );
};

function App(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <div>
          <CssBaseline />
          {/* <div>
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDow implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
        </div> */}
          <div style={{ width: "84%", float: "right" }}>
            <Switch>
              <Route path="/" exact component={Login} />
              {/* <Route path="/" exact component={Home} /> */}
              <Route path="/Home" exact component={Home} />
              <Route path="/Users" exact component={UserPage} />
              <Route path="/Table" exact component={TableView} />
              <Route path="/Roles" exact component={RolePage} />
              <Route path="/Settings" exact component={SettingsPage} />
              <Route path="/Reports" exact component={ReportsPage} />
              <Route path="/Permissions" exact component={PermissionsPage} />

              <Route path="/Frames" exact component={UserPage} />

              <Route path="/404" exact component={fourzerofour} />
              {/* <Route path="/signin" exact component={Login} /> */}
              {/* <Redirect to="/404" /> */}
            </Switch>
          </div>
        </div>
      </ThemeProvider>
    </UserProvider>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
